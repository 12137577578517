<template>
  <div id="app">

    <v-app>

      <!-- Sizes your content based upon application components -->
      <v-main>

        <!-- Provides the application the proper gutter -->
        <v-container fluid>

          <!-- Main logo and language selector -->
          <v-card max-width="600" class="mx-auto" flat>
            <v-card-text class="text-center">
              <img class="img-logo" src="@/assets/home-logo.png"/>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-select
                style="max-width: 90px"
                :items="languages"
                dense
                outlined
                v-model="currentLanguage"
                @change="changeLanguage"
              >
                <template v-slot:selection="{ item }">
                  <v-icon></v-icon>
                  <country-flag :country='item.icon' size='normal'/>
                </template>
                <template v-slot:item="{ item }">
                  <country-flag :country='item.icon' size='normal'/>
                </template>
              </v-select>
            </v-card-actions>             
          </v-card>

          <!-- Overlay for progress -->
          <v-overlay :value="progressOverlay">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-overlay>

          <router-view v-on="listeners"></router-view>
        </v-container>
      </v-main>

      <!-- Snack bar -->
          <v-snackbar
            v-model="snackbar.show"
            :color="snackbar.color"
          >
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="snackbar.show = false"
              >
                Chiudere
              </v-btn>
            </template>
          </v-snackbar>
      <!-- End snack bar -->


    </v-app>

  </div>
</template>

<script>

export default {

    // Data
    data () {
      return {

        currentLanguage: 'it',

        languages: [
          {value: 'it', text:'Italiano', icon: 'it'},
          {value: 'en', text:'English', icon: 'us'},
          {value: 'jp', text:'Japanese', icon: 'jp'},
          {value: 'es', text:'Español', icon: 'es'},
          {value: 'pt', text:'Portuguese', icon: 'pt'},
          {value: 'fr', text:'Français', icon: 'fr'},
        ],

        lang: [],

        snackbar: {
          show: false,
          color: 'dark',
          text: ''
        },
        menuDrawer: null,
        progressOverlay: false,
        darkMode: false,

        // Listeners
        listeners: {
          'showProgress': () => this.showProgress(),
          'hideProgress': () => this.hideProgress(),
          'showSnack': (msg, color) => this.showSnack(msg, color),
        },

      }
    },

    // Computed data
    computed: {
        loggedIn() {
          return this.$store.getters.userAuth;
        },
        isAdmin() {
          return this.$store.getters.isAdmin;
        },
        userLevel() {
          return this.$store.getters.userLevel;
        }
    },

    // Methods
    methods: {
      changeLanguage() {
        this.$store.dispatch('changeLanguage', {language: this.currentLanguage}); 
      },

      navigate(route) {
        // Quit if progress screen is showing
        if (this.progressOverlay)
          return 0;
        if (this.$route.name != route)
          this.$router.push({name: route});
      },
      showProgress() {
        this.progressOverlay = true;
      },
      showSnack(msg, color = 'dark') {
        this.snackbar.text = msg;
        this.snackbar.color = color;
        this.snackbar.show = true;
      },
      hideProgress() {
        this.progressOverlay = false;
      },
      toggleDarkMode() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        //this.darkmodeNotification = true;
      },
    },

    // Events
    mounted() {

      this.lang = this.$lang.it;

    },

    // Components
    components: {
      
    },

}

</script>

<style>

.img-logo {
  width: 178px;
}

</style>