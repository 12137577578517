import env from '../env'
import axios from 'axios'

const api_url = env.api;

const api = {

    login: async values => {
        return axios
            .post(api_url + 'Auth/', {
                funct: 'login',
                values
            })
            .then(response => {
                return response.data;
            });
    },

    getAllPlaces: async () => {
        return axios
            .post(api_url + 'AVPN/', {
                funct: 'getAll'
            })
            .then(response => {
                return response.data;
            });
    },

    vote: async values => {
        return axios
            .post(api_url + 'AVPN/', {
                funct: 'vote',
                values
            })
            .then(response => {
                return response.data;
            });
    },

    checkVote: async () => {
        return axios
            .post(api_url + 'AVPN/', {
                funct: 'checkVote'
            })
            .then(response => {
                return response.data;
            });
    },

    sendPoll: async values => {
        return axios
            .post(api_url + 'AVPN/', {
                funct: 'sendPoll',
                values
            })
            .then(response => {
                return response.data;
            });
    },
  
    // Auth
    authCheck: async () => {
        return axios
            .post(api_url + 'Auth/', {
                funct: 'check'
            })
            .then(response => {
                return response.data;
            });
    },

};

export default api