let env

if (process.env.NODE_ENV=='production')  {
    env = {
        api: '/api/',
        uploadsFolder: '/api/uploads/',        
    }
} else {
    env = {
        // DEV
        api: 'http://localhost/api/',
        uploadsFolder: 'http://localhost/api/uploads/',
        // Lan
        /*
        api: 'http://192.168.43.187/api/',
        uploadsFolder: 'http://localhost/api/uploads/',
        */
    }
}

env.VOTING_CLOSED = false;

export default env