<template>
  <div class="home">  

    <!-- Just voted and OK -->
    <v-card class="mx-auto mb-15" max-width="600" v-if="voteOk">
      <v-card-title>
        <v-spacer></v-spacer>
          Best Pizzeria AVPN 2024
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <!-- Ok icon -->
        <v-icon class="mb-4 ok-icon" color="green" x-large>mdi-checkbox-marked-circle-outline</v-icon>

        <br />

        <span v-html="lang.voteOk">
        </span>
      </v-card-text>
    </v-card>

    <span v-if="voteOk">
      {{lang.voteOkQuestionnaire}}
    </span>

    <!-- Poll -->
    <v-card class="mx-auto mt-8 text-left" max-width="600" flat v-if="voteOk">
      <v-card-title>
        <v-spacer></v-spacer>
          {{lang.questionnaire}}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-if="!pollOk">
        <form ref="poll">

          <!-- 1 -->
          <span class="poll-title">1. {{lang.q1.q}}</span>
          <v-radio-group v-model="poll.q_1" mandatory>
            <v-radio :label="lang.q1.a1" value="0"></v-radio>
            <v-radio :label="lang.q1.a2" value="1"></v-radio>
            <v-radio :label="lang.q1.a3" value="2"></v-radio>
            <v-radio :label="lang.q1.a4" value="3"></v-radio>
          </v-radio-group>
          <br />

          <!-- 2 -->
          <span class="poll-title">2. {{lang.q2.q}}</span>
          <v-radio-group v-model="poll.q_2" mandatory>
            <v-radio :label="lang.q2.a1" value="0"></v-radio>
            <v-radio :label="lang.q2.a2" value="1"></v-radio>
            <v-radio :label="lang.q2.a3" value="2"></v-radio>
            <v-radio :label="lang.q1.a4" value="3"></v-radio>
          </v-radio-group>
          <br />

          <!-- 3 -->
          <span class="poll-title">3. {{lang.q3.q}}</span>
          <v-radio-group v-model="poll.q_3" mandatory>
            <v-radio :label="lang.q3.a1" value="0"></v-radio>
            <v-radio :label="lang.q3.a2" value="1"></v-radio>
            <v-radio :label="lang.q3.a3" value="2"></v-radio>
            <v-radio :label="lang.q3.a4" value="3"></v-radio>
          </v-radio-group>
          <br />

          <!-- 4 -->
          <span class="poll-title">4. {{lang.q4.q}}</span>
          <v-radio-group v-model="poll.q_4" mandatory>
            <v-radio :label="lang.q4.a1" value="0"></v-radio>
            <v-radio :label="lang.q4.a2" value="1"></v-radio>
            <v-radio :label="lang.q4.a3" value="2"></v-radio>
            <v-radio :label="lang.q4.a4" value="3"></v-radio>
          </v-radio-group>
          <br />

          <!-- 5 -->
          <span class="poll-title">5. {{lang.q5.q}} </span>
          <v-radio-group v-model="poll.q_5" mandatory>
            <v-radio :label="lang.q5.a1" value="0"></v-radio>
            <v-radio :label="lang.q5.a2" value="1"></v-radio>
            <v-radio :label="lang.q5.a3" value="2"></v-radio>
            <v-radio :label="lang.q5.a4" value="3"></v-radio>
          </v-radio-group>
          <br />

          <v-btn block color="success">{{lang.sendVote}}</v-btn>
                
        </form>
      </v-card-text>
      <v-card-text v-else class="text-center">
        <!-- Poll saved -->
        <v-icon class="mb-4 ok-icon" color="green" x-large>mdi-checkbox-marked-circle-outline</v-icon>        
      </v-card-text>
    </v-card>

    <!-- Already voted -->
    <v-card class="mx-auto" max-width="600" v-if="alreadyVoted">
      <v-card-title>
        <v-spacer></v-spacer>
          Best Pizzeria AVPN 2024
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <br /><br />
        <h3 class="red-color" v-html="lang.alreadyVoted">
        </h3>
        <br /><br />
      </v-card-text>
    </v-card>

    <v-overlay v-model="confirmVoteOverlay">
      <v-card light>
        <v-card-title>{{lang.confirmVote}}</v-card-title>
        <v-card-text>
          {{lang.voteFor}}
          <h3 class="red-color">
            {{ (confirmVoteOverlay) ? items.find(element => (element.newnum == form.vote1)).nomeaz : '' }}<br />
          </h3>
          <br />
          <b>
            {{lang.voteText1}}<br />
            {{lang.voteText2}}
          </b>
          <br /><br />
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="confirmVoteOverlay = false">{{lang.cancel}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="vote">{{lang.confirmVote}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <v-card class="mx-auto text-left" max-width="600" v-if="!alreadyVoted && !voteOk && showForm">
      <v-card-title class="title">{{lang.voteTitle}}</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit="showVoteConfirm">
          <v-text-field :rules="[...rules.required, ...rules.placeExists]" v-model="form.vote1" :label="lang.associateNumber"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click="showVoteConfirm" large>{{lang.vote}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>  

    <!-- Elenco pizzerie -->
    <template v-if="!voteOk && !alreadyVoted">
      <br /><br /><br />
      <h1>{{lang.tableTitle}}</h1>
      <br /><br />

      <img src="@/assets/logo_avpn.jpg" />


      <v-card class="mx-auto text-left" max-width="1200" flat>
        <v-card-text>
          <v-text-field style="max-width: 400px" @input="search" v-model="searchBar" :label="lang.search" outlined dense></v-text-field>
        </v-card-text>
      </v-card>  

      <!-- desktop -->
      <v-card class="mx-auto d-none d-sm-block" max-width="1200" flat>
        <v-card-text>
          <template>
            <v-data-table
              :headers="headers"
              :items="filteredItems"
              :items-per-page="100"
              class="elevation-1"
            >
              <template v-slot:item.nomeaz="{ item }">
                <span class="red-color">{{item.nomeaz}}</span>
              </template>

              <template v-slot:item.location="{ item }">
                {{item.city}}, {{item.nazione}}
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>

      <!-- mobile -->
      <v-card class="mx-auto d-block d-sm-none" max-width="1200" flat>
        <v-card-text>
          <template>
            <v-data-table
              :headers="headersMobile"
              :items="filteredItems"
              :items-per-page="100"
              class="elevation-1"
            >
              <template v-slot:item.nomeaz="{ item }">
                <span class="red-color">{{item.nomeaz}}</span>
              </template>

              <template v-slot:item.location="{ item }">
                {{item.city}}, {{item.nazione}}
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </template>

  </div>
</template>

<script>

export default {
  name: 'Home',

  // Data
  data() {
    return {

      confirmVoteOverlay: false,

      alreadyVoted: false,
      voteOk: false,
      pollOk: false,

      showForm: false,

      headers: [
        {text: 'Numero Associato', value: 'newnum'},
        {text: 'Pizzeria', value: 'nomeaz'},
        {text: 'Città', value: 'city'},
        {text: 'Provincia', value: 'provincia'},
        {text: 'Regione', value: 'regione'},
        {text: 'Nazione', value: 'nazione'},
        {text: 'Location', value: 'location'},
      ],

      headersMobile: [
        {text: 'Numero Associato', value: 'newnum'},
        {text: 'Pizzeria', value: 'nomeaz'},        
        {text: 'Location', value: 'location'},
      ],

      searchBar: '',

      items: [],

      rules: {
        required: [ value => (!value) ? this.lang.notEmptyFields : true ],
        placeExists: [ value => (!this.items.find(element => (element.newnum === value) )) ? this.lang.numberNotFound : true ],
      },

      form: {
        vote1: null,
        vote2: null,
        vote3: null,
      },

      // Poll
      poll: {
        q_1: null,
        q_2: null,
        q_3: null,
        q_4: null,
        q_5: null,
      },

    }
  },

  methods: {

    showVoteConfirm(e) {
      e.preventDefault()

      if (!this.$refs.form.validate())
        return;

      this.confirmVoteOverlay = true;
    },

    vote() {
      if (!this.$refs.form.validate())
        return;

      this.$emit('showProgress');

      this.$api.vote(this.form).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.confirmVoteOverlay = false;
          this.voteOk = true;
        } else {
          // Check the error message we get from API
          if (data.values.msg=="error1")
            this.$emit('showSnack', this.lang.errorVoteYourself, 'error');
          else if (data.values.msg=="error2")
              this.$emit('showSnack', this.lang.errorSameOwner, 'error');
          else
            this.$emit('showSnack', data.values.msg, 'error');

          this.confirmVoteOverlay = false;
        }
      });

    },

    sendPoll() {
      this.$emit('showProgress');

      this.$api.sendPoll(this.poll).then(data => {
        this.$emit('hideProgress');

        if (data.ok) {
          this.pollOk = true;
        } else {          
          this.$emit('showSnack', data.values?.msg || 'Error processing poll', 'error');
        }
      });
    },

    search() {

    },

  },

  mounted() {
    this.lang = this.$lang.it;    

    this.$emit('ShowProgress');
    this.$api.getAllPlaces().then(data => {
      this.items = data.values.places;

      // Check if already voted
      this.$api.checkVote().then(data => {
        if (!data.ok)
          this.alreadyVoted = true;
        else 
          this.showForm = true;
        this.$emit('hideProgress');
      });
    });

    // debug
    this.voteOk = true;
  },

  computed: {

    filteredItems: function () {

      let filtered = this.items;

      // Filter by name
      if (this.searchBar != '') {        
        filtered = filtered.filter(item => {
           return (item.nomeaz.toLowerCase().search(this.searchBar.toLowerCase())!=-1);
           });
      }

      return filtered;
    },

    lang: {  

      get() {    
        switch(this.$store.getters.language) {
          case 'it':
            return this.$lang.it;
          case 'en':
            return this.$lang.en;
          case 'jp':
            return this.$lang.jp;
          case 'es':
            return this.$lang.es;
          case 'pt':
            return this.$lang.pt;
          case 'fr':
            return this.$lang.fr;
          default:
            return this.$lang.en;
        }
      },

      set(newLang) {
        return newLang
      }      

    }

  },

  watch: {

      lang: function () {
        switch(this.$store.getters.language) {
          case 'it':
            // Adjust headers
            this.headers = this.$lang.it.voteHeaders;
            break;
          case 'jp':
            // Adjust headers
            this.headers = this.$lang.jp.voteHeaders;
            break;
          default:
            // Adjust headers
            this.headers = this.$lang.en.voteHeaders;
        }
      },

  },

}

</script>

<style scoped>

  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }
  
  .subtitle {
    font-size: 110%;
    color:  #000;
  }

  .home {
    text-align: center;
  }

  h1 {
    color: #3bb44b;
  }

  .red-color {
    color: #ee3b41;
  }

  .ok-icon {
    font-size: 350% !important;
  }

  .poll-title {
    font-weight: bold;
    color: #000;
  }

</style>