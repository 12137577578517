import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
//import colors from 'vuetify/lib/util/colors'
import env from './env'
import api from './api'
import lang from './lang'
import CountryFlag from 'vue-country-flag'

Vue.prototype.$env = env;
Vue.prototype.$api = api;
Vue.prototype.$lang = lang;

Vue.use(Vuetify)

let opts = {
  theme: {
    themes: {
      light: {
        primary: '#3bb44b', // Green
        accent: '#ee3b41', // Red
        /*        
        primary: colors.grey.lighten2, // White
        secondary: '#97C8A3', // Green
        accent: '#B71C1C', // Red
        error: colors.red.accent3,
        */
      },
      dark: {
        /*
        primary: colors.grey.darken4, // Black
        secondary: colors.grey.darken4, // Black
        accent: colors.grey.darken2, // Dark grey
        */
      },
    },
  },
}

const vuetify = new Vuetify(opts)

Vue.component('country-flag', CountryFlag)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')