import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

import env from '../env'

import Home from '../views/Home.vue'
import Vote from '../views/Vote.vue'
import VoteTest from '../views/Vote_test.vue'
import VotingClosed from '../views/VotingClosed.vue'

const VOTING_CLOSED = env.VOTING_CLOSED;

Vue.use(VueRouter)

const routeHome = VOTING_CLOSED ? { path: '/', name: 'VotingClosed', component: VotingClosed } : { path: '/', name: 'Home', component: Home };

const routes = [
  routeHome,
  { path: '/vote', name: 'Vote', component: Vote },
  { path: '/vote_test', name: 'Vote_test', component: VoteTest },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let restrictedAreas = [
  'Profile',
  'Logout',
  'AddNews',
];

let adminAreas = [
  'ManageNews',
];

router.beforeEach((to, from, next) => {

  if (restrictedAreas.includes(to.name)) {
    if (store.getters.userAuth)
      next();      
    else
      next({name: 'Login'});
  } else if (adminAreas.includes(to.name)) {
    if (store.getters.isAdmin)
      next();      
    else
      next({name: 'Login'});
  } else {
    next();
  }
});

export default router