import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    userEmail: null,
    userLevel: 0,
    username: false,
    userId: false,
    language: 'it',
  },

  getters: {
    userAuth: state => {
      return state.loggedIn;
    },
    userLevel: state => {
      return state.userLevel;
    },
    isAdmin: state => {
      return (state.userLevel > 1) ? true : false;
    },
    language: state => {
      return state.language;
    },
  },

  mutations: {

    login: state => {
      state.loggedIn = true;     
    },

    setUsername: (state, value) => {
      state.username = value;
    },

    setUserId: (state, value) => {
      state.userId = value;
    },

    setUserLevel: (state, level) => {
      state.userLevel = level;
    },

    setUserEmail: (state, email) => {
      state.userEmail = email;
    },

    logout: state => {
      // Reset all values
      state.loggedIn = false; 
      state.userEmail = null;
      state.userLevel = 0;
    },

    setLanguage: (state, value) => {
      state.language = value;
    },

  },

  actions: {

    changeLanguage: ({commit}, params) => {
      commit('setLanguage', params.language);
    },

    login: ({commit}, params) => {
      commit('login');
      commit('setUserId', params.id);
      commit('setUsername', params.username);
    },

    logout: ({commit}) => {
      commit('logout');
    },

  },

  modules: {
  },

})