const lang = {

    it: {
        loginTitle: 'Accedi',
        user: 'Utente',
        password: 'Password',
        login: 'Accedi',
        loginOk: 'Accesso eseguito',
        userNotFound: 'Account non trovato o password errata',
        associateNumber: 'Numero associato',
        notEmptyFields: 'Il campo non può essere vuoto',
        voteTitle: 'Vota la miglior pizzeria tra tutti gli affiliati AVPN',
        voteSubtitle: '(l\'ordine delle preferenze non influisce sulla classifica)',
        vote: 'Vota',
        tableTitle: 'Elenco pizzerie AVPN',
        search: 'Cerca pizzeria',
        voteOk: 'Grazie per aver espresso il tuo voto!<br /><br />Non è possibile modificare o eliminare il voto.<br /><br />La classifica verrà resa pubblica solo a fine concorso.',
        voteError: 'Errore',
        alreadyVoted: 'Hai già espresso il tuo voto. Non è possibile modificare o eliminare il voto.<br /><br />La classifica verrà resa pubblica solo a fine concorso.',
        voteFor: 'Vota per',
        voteText1: 'Ti ricordiamo che il voto espresso non sarà più modificabile.',
        voteText2: 'La classifica verrà resa pubblica solo a fine concorso.',
        cancel: 'Annulla',
        confirmVote: 'Conferma voto',
        voteHeaders: [
            {text: 'Numero Associato', value: 'newnum'},
            {text: 'Pizzeria', value: 'nomeaz'},
            {text: 'Città', value: 'city'},
            {text: 'Provincia', value: 'provincia'},
            {text: 'Regione', value: 'regione'},
            {text: 'Nazione', value: 'nazione'},
          ],
        numberNotFound: 'Numero associato non trovato',
        errorSamePlace: 'Non è possibile votare la stessa pizzeria più di una volta',
        errorVoteYourself: 'Errore: non puoi votare te stesso',
        errorSameOwner: 'Errore: non è possibile votare una pizzeria dello stesso proprietario',

        voteOkQuestionnaire: 'Ti chiediamo di dedicarci un altro minuto per aiutarci a capire le tendenze del settore pizzeria.',

        questionnaire: 'Questionario',
        q1: {
            q: 'Cosa ritieni più importante nella valutazione di una pizzeria?',
            a1: 'Servizio',
            a2: 'Prodotto',
            a3: 'Location',
            a4: 'Abbinamenti (vini e birre)',
        },
        q2: {
            q: 'A chi ti affidi nella promozione della tua pizzeria?',
            a1: 'Social',
            a2: 'TripAdvisor',
            a3: 'Guide cartacee e giornali',
            a4: 'Guide web e blog',
        },
        q3: {
            q: 'Ogni quanto cambi il tuo menù?',
            a1: 'Mai',
            a2: '1 volta l’anno',
            a3: '2 volte l’anno',
            a4: '4 volte l’anno',
        },
        q4: {
            q: 'Qual è il target della tua pizzeria?',
            a1: 'Gruppi di amici',
            a2: 'Famiglie',
            a3: 'Coppie',
            a4: 'Lavoratori/Professionisti',
        },
        q5: {
            q: 'Quando hai maggior affluenza nella tua pizzeria?',
            a1: 'In settimana',
            a2: 'Sabato',
            a3: 'Domenica',
            a4: 'Ricorrenze',
        },
        sendVote: 'Invia',
        votingClosed: 'Il concorso è scaduto!',
    },

    en: {
        loginTitle: 'Sign in',
        user: 'Username',
        password: 'Password',
        login: 'Sign in',
        loginOk: 'Signed in',
        userNotFound: 'Account not found or wrong password',
        associateNumber: 'Member number',
        notEmptyFields: 'The field cannot be empty',
        voteTitle: 'Vote for the best pizzeria among all AVPN members',
        voteSubtitle: '(the order of preferences does not affect the ranking)',
        vote: 'Vote',
        tableTitle: 'List of AVPN Members Pizzerias ',
        search: 'Find pizzeria',
        voteOk: 'Thanks for voting!',
        voteError: 'Error',
        alreadyVoted: 'You have already sent your vote. It is not possible to modify or delete the vote.<br /><br />The ranking will be made public only at the end of the competition.',
        voteFor: 'Vote for',
        voteText1: 'We remind you that the vote expressed will no longer be modifiable.',
        voteText2: 'The ranking will be made public only at the end of the competition.',
        cancel: 'Cancel',
        confirmVote: 'Confirm vote',
        voteHeaders: [
            {text: 'Member number', value: 'newnum'},
            {text: 'Pizzeria', value: 'nomeaz'},
            {text: 'City', value: 'city'},
            {text: 'Province', value: 'provincia'},
            {text: 'Region or State', value: 'regione'},
            {text: 'Country', value: 'nazione'},
          ],
        numberNotFound: 'Member number not found',
        errorSamePlace: 'It is not possible to vote for the same pizzeria more than once',
        errorVoteYourself: 'Error: it is not possible to vote for yourself',
        errorSameOwner: 'Error: it is not possible to vote for a pizzeria of the same owner',

        voteOkQuestionnaire: 'We ask you to take another minute to help us understand the trends in the pizzeria sector.',

        questionnaire: 'Questionnaire',
        q1: {
            q: 'What do you value most in your restaurant?',
            a1: 'Service',
            a2: 'Product',
            a3: 'Location',
            a4: 'Combinations',
        },
        q2: {
            q: 'Who do you think is most effective for the promotion of your pizzeria?',
            a1: 'Social',
            a2: 'TripAdvisor',
            a3: 'Print and newspaper guides',
            a4: 'Web guides and blogs',
        },
        q3: {
            q: 'How often do you change your menu?',
            a1: 'Never',
            a2: 'once a year',
            a3: 'twice a year',
            a4: '4 times a year',
        },
        q4: {
            q: 'What is the target of your pizzeria?',
            a1: 'Groups of friends',
            a2: 'Families',
            a3: 'Couples',
            a4: 'Workers / Professionals',
        },
        q5: {
            q: 'When do you have the greatest turnout in your pizzeria?',
            a1: 'During the week',
            a2: 'Saturday',
            a3: 'Sunday',
            a4: 'Ricorrenze',
        },
        sendVote: 'Submit',
        votingClosed: 'The contest is no longer valid!',
    },

    jp: {
        loginTitle: 'ログイン',
        user: 'ユーザーネーム',
        password: 'パスワード',
        login: 'ログイン',
        loginOk: 'ログイン出来ました',
        userNotFound: 'アカウント不在、またはパスワードが異なります',
        associateNumber: '認定番号',
        notEmptyFields: '空欄部分をご記入ください',
        voteTitle: 'AVPN認定店舗の中でベスト3に投票',
        voteSubtitle: '(投票順番によってランキングへの影響はございません)',
        vote: '投票',
        tableTitle: 'AVPN 認定店舗覧',
        search: '店舗検索',
        voteOk: '投票出来ました',
        voteError: 'エラー',
        alreadyVoted: '既に投票しました。票を変更、または削除することが出来ません。<br /><br />最終ランキングはコンテスト終了後発表されます。',
        voteFor: 'Vote for',
        voteText1: '投票の変更が不可であることをご了承ください。.',
        voteText2: '最終ランキングはコンテスト終了後発表されます。',
        cancel: 'キャンセル',
        confirmVote: '投票を確認',
        voteHeaders: [
            {text: '認定番号', value: 'newnum'},
            {text: '認定店', value: 'nomeaz'},
            {text: '市', value: 'city'},
            {text: '県', value: 'provincia'},
            {text: '地方', value: 'regione'},
            {text: '国', value: 'nazione'},
          ],
        numberNotFound: '認定番号が見つかりませんでした',
        errorSamePlace: '同じ店舗に複数回投票出来ません',
        errorVoteYourself: 'エラー:ご自身のピッツェリーアに投票出来ません',
        errorSameOwner: 'エラー:オーナーが同一であるピッツェリーアに投票出来ません',

        voteOkQuestionnaire: 'ご投票頂き、ありがとうございます。真のナポリピッツァ協会は、ピッツァ業界のトレンドをより明確に把握できるように後一分のご協力をお願い致します。',

        questionnaire: '',
        q1: {
            q: 'ご自身の店舗で最も重要にされていることは何ですか?',
            a1: 'サービス',
            a2: '商品',
            a3: 'ロケーション ',
            a4: '味の組み合わせ',
        },
        q2: {
            q: 'ご自身の店舗をより効率よく宣伝できるものは次からどれだと思っていますか?',
            a1: 'SNS',
            a2: 'TripAdvisor',
            a3: 'ガイドや新聞',
            a4: 'ウェブガイドまたはブログ',
        },
        q3: {
            q: 'どのぐらいの頻度でメニューを変えますか?',
            a1: '変えない',
            a2: '年に一回 ',
            a3: '年に二回 ',
            a4: '年に四回',
        },
        q4: {
            q: 'どのような客層がご自身の店舗を訪れますか。',
            a1: '友達グループ',
            a2: '家族',
            a3: 'カップル',
            a4: '社会人･会社員',
        },
        q5: {
            q: '店舗が最も込み合っているのはいつですか?',
            a1: '平日',
            a2: '土曜日',
            a3: '日曜日',
            a4: '祝日',
        },
        sendVote: '送信',
        votingClosed: 'コンテストが終わりました！',
    },

    es: {
        loginTitle: 'Iniciar sesión',
        user: 'Usuario',
        password: 'Contraseña',
        login: 'Iniciar sesión',
        loginOk: 'Acceso logrado',
        userNotFound: 'Account no encontrado o password equivocado',
        associateNumber: 'Número asociado',
        notEmptyFields: 'El campo no puede estar vacío',
        voteTitle: 'Vota la mejor pizzería entre todos los afiliados de AVPN',
        voteSubtitle: '(el orden de preferencia no afecta la clasificación)',
        vote: 'Vota',
        tableTitle: 'Lista de pizzerías AVPN',
        search: 'Buscar pizzería',
        voteOk: '¡Gracias por votar! No se puede modificar ni eliminar la nota. La clasificación sólo se hará pública al final del concurso.',
        voteError: 'Error',
        alreadyVoted: 'Ya has votado. No se puede modificar o eliminar el voto. La clasificación sólo se hará pública al final del concurso.',
        voteFor: 'Vota por',
        voteText1: 'Le recordamos que el voto emitido ya no será modificable.',
        voteText2: ' La clasificación sólo se hará pública al final del concurso.',
        cancel: 'Cancelar',
        confirmVote: 'Confirmar Voto',
        voteHeaders: [
            {text: 'Número asociado ', value: 'newnum'},
            {text: 'Pizzeria', value: 'nomeaz'},
            {text: 'Ciudad', value: 'city'},
            {text: 'Provincia', value: 'provincia'},
            {text: 'Region', value: 'regione'},
            {text: 'Nación', value: 'nazione'},
          ],
        numberNotFound: 'Número de asociado no encontrado',
        errorSamePlace: 'No es posible votar la misma pizzería más de una vez',
        errorVoteYourself: 'Error: no puedes votar por ti mismo',
        errorSameOwner: 'Error: no es posible votar una pizzeria del mismo propietario',

        voteOkQuestionnaire: 'Gracias por la votación. Te pedimos que nos dediques un minuto más para ayudarnos a entender las tendencias del sector de la pizzería.',

        questionnaire: 'Cuestionario',
        q1: {
            q: '¿A qué le das más importancia en tu local?',
            a1: 'Servicio',
            a2: 'Producto',
            a3: 'Ubicación',
            a4: 'Emparejamientos',
        },
        q2: {
            q: '¿Quién cree que es más eficaz para la promoción de su pizzería?',
            a1: 'Redes sociales',
            a2: 'TripAdvisor',
            a3: 'Guías de papel y periódicos',
            a4: 'Guías web y blogs',
        },
        q3: {
            q: '¿Con qué frecuencia cambias tu menú?',
            a1: 'Nunca',
            a2: '1 vez al año',
            a3: '2 veces al año',
            a4: '4 veces al año',
        },
        q4: {
            q: '¿Cuál es el objetivo de su pizzería?',
            a1: 'Grupos de amigos',
            a2: 'Familias',
            a3: 'Parejas',
            a4: 'Trabajadores/Profesionales',
        },
        q5: {
            q: '¿Cuándo tienes más afluencia en tu pizzería?',
            a1: 'En semana',
            a2: 'Sábado',
            a3: 'Domingo',
            a4: 'Aniversarios',
        },
        sendVote: 'Enviar',
        votingClosed: 'El concurso ha terminado!',
    },

    pt: {
        loginTitle: 'Entrar',
        user: 'Usuario',
        password: 'Senha',
        login: 'Entrar',
        loginOk: 'Acesso realizado',
        userNotFound: 'Conta não encontrada ou senha errada',
        associateNumber: 'Número Associado ',
        notEmptyFields: 'O campo não pode estar vazio',
        voteTitle: 'Classifique a melhor pizzaria entre todos os afiliados da AVPN',
        voteSubtitle: '(a ordem das preferências não afeta a classificação)',
        vote: 'Confirmar Voto',
        tableTitle: 'Lista de pizzerías AVPN',
        search: 'Pesquisar pizzarias',
        voteOk: 'Obrigado por votar! Você não pode editar ou excluir a nota. O ranking só será tornado público no final do concurso.',
        voteError: 'Erro',
        alreadyVoted: 'Você já votou. Você não pode editar ou excluir sua votação. A classificação só será tornada pública no final do concurso.',
        voteFor: 'Vote em',
        voteText1: 'Lembramos que o voto expresso não será mais modificável.',
        voteText2: 'O ranking só será tornado público no final do concurso.',
        cancel: 'Cancelar',
        confirmVote: 'Confirmar Voto',
        voteHeaders: [
            {text: 'Número Associado ', value: 'newnum'},
            {text: 'Pizzaria', value: 'nomeaz'},
            {text: 'Cidade', value: 'city'},
            {text: 'Província', value: 'provincia'},
            {text: 'Região', value: 'regione'},
            {text: 'País', value: 'nazione'},
          ],
        numberNotFound: 'Número de associado não encontrado',
        errorSamePlace: 'It is not possible to vote for the same pizzeria more than once',
        errorVoteYourself: 'Erro: você não pode votar em si mesmo',
        errorSameOwner: 'Erro: não é possível votar em uma pizzaria do mesmo proprietário',

        voteOkQuestionnaire: 'Obrigado pela votação, Pedimos que você nos dedique mais um minuto para nos ajudar a entender as tendências do setor de pizzarias.',

        questionnaire: 'Questionário',
        q1: {
            q: 'A que é que você dá mais importância no seu local?',
            a1: 'Serviço',
            a2: 'Produto',
            a3: ' Localização ',
            a4: 'Correspondências',
        },
        q2: {
            q: 'Quem você acha que é mais eficaz para a promoção da sua pizzaria?',
            a1: 'Social',
            a2: 'TripAdvisor',
            a3: 'Guias impressos e jornais',
            a4: 'Guias da web e blogs',
        },
        q3: {
            q: 'Com que frequência muda o seu menu?',
            a1: 'Nunca',
            a2: '1 vez por ano',
            a3: '2 vezes por ano',
            a4: '4 vezes por ano ',
        },
        q4: {
            q: 'Qual é o alvo da sua pizzaria?',
            a1: 'Grupos de amigos',
            a2: 'Familias',
            a3: 'Casais ',
            a4: 'Trabalhadores/Profissionais',
        },
        q5: {
            q: 'Quando é que tem mais afluência à sua pizzaria?',
            a1: 'Durante a semana',
            a2: 'Sábado',
            a3: 'Domingo',
            a4: 'Recorrências',
        },
        sendVote: 'Enviar',
        votingClosed: 'The contest is no longer valid!',
    },

    fr: {
        loginTitle: 'Connexion',
        user: 'Utilisateur',
        password: 'Mot de passe',
        login: 'Connexion',
        loginOk: 'Connecté',
        userNotFound: 'Compte introuvable ou mauvais password',
        associateNumber: 'Numéro associé  ',
        notEmptyFields: 'Le champ ne peut pas être vide',
        voteTitle: 'Votez pour la meilleure pizzeria parmi tous les affiliés AVPN',
        voteSubtitle: '(l\'ordre des préférences n\'affecte pas le classement)',
        vote: 'Valider le vote ',
        tableTitle: 'Liste des pizzerias avpn ',
        search: 'Recherche pizzeria',
        voteOk: 'Merci d\'avoir voté ! Il n\'est pas possible de modifier ou de supprimer la note. Le classement ne sera rendu public qu\'à la fin de la compétition.',
        voteError: 'Erreur',
        alreadyVoted: 'Vous avez déjà voté. Il n\'est pas possible de modifier ou de supprimer la note. Le classement ne sera rendu public qu\'à l\'issue de la compétition',
        voteFor: 'Voter pour ',
        voteText1: 'Nous vous rappelons que le vote exprimé ne sera plus modifiable.',
        voteText2: 'Le classement ne sera rendu public qu\'à l\'issue de la compétition.',
        cancel: 'Annuler',
        confirmVote: 'Valider le vote ',
        voteHeaders: [
            {text: 'Numéro associé ', value: 'newnum'},
            {text: 'Pizzeria', value: 'nomeaz'},
            {text: 'Ville', value: 'city'},
            {text: 'Province', value: 'provincia'},
            {text: 'Région', value: 'regione'},
            {text: 'Pays', value: 'nazione'},
          ],
        numberNotFound: 'Numéro associé introuvable',
        errorSamePlace: 'It is not possible to vote for the same pizzeria more than once',
        errorVoteYourself: 'Erreur : vous ne pouvez pas voter vous-même',
        errorSameOwner: 'Erreur: il n\'est pas possible de voter pour une pizzeria du même propriétaire',

        voteOkQuestionnaire: 'Merci d\'avoir voté, nous vous demandons de prendre une minute de plus pour nous aider à comprendre les tendances du secteur des pizzerias.',

        questionnaire: 'Questionnaire',
        q1: {
            q: '1. Qu\'appréciez-vous le plus dans votre restaurant ?',
            a1: 'Service ',
            a2: 'Produit',
            a3: 'Localisation',
            a4: 'Combinaisons',
        },
        q2: {
            q: 'Selon vous, qui est le plus efficace pour la promotion de votre pizzeria ?',
            a1: 'Réseaux sociaux ',
            a2: 'TripAdvisor',
            a3: 'Guides imprimés et journaux',
            a4: 'Guides Web et blogs',
        },
        q3: {
            q: 'À quelle fréquence changez-vous votre menu ?',
            a1: 'Jamais ',
            a2: '1 fois par an ',
            a3: '2 fois par an',
            a4: '4 fois par an',
        },
        q4: {
            q: 'Quelle est la cible de votre pizzeria?',
            a1: 'Groupes d\'amis ',
            a2: 'Familles',
            a3: 'Couples ',
            a4: 'Travailleurs / Professionnels',
        },
        q5: {
            q: 'Quand avez-vous le plus de participation dans votre pizzeria ?',
            a1: 'En semaine ',
            a2: 'Samedi',
            a3: 'Dimanche ',
            a4: 'Anniversaires',
        },
        sendVote: 'Soumettre',
        votingClosed: 'The contest is no longer valid!',
    },

};

export default lang