<template>
  <div class="home">  
    <!-- Login -->      

    <v-card class="mx-auto text-left" max-width="600">
      <v-card-title>{{lang.loginTitle}}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field :rules="rules.required" :label="lang.user" v-model="form.username"></v-text-field>
          <v-text-field :rules="rules.required" v-model="form.password" :label="lang.password" :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show_password = !show_password"></v-text-field>
        </v-form>
        <br />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click="login" large>{{lang.login}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
 
  </div>
</template>

<script>

export default {
  name: 'Home',

  // Data
  data() {
    return {

      show_password: false,

      rules: {
        required: [ value => (!value) ? this.lang.notEmptyFields : true ],
      },
     
      form: {
        username: null,
        password: null,
      },

    }
  },

  methods: {

    login() {
      if (!this.$refs.form.validate())
        return;

      this.$emit('showProgress');

      this.$api.login(this.form).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.$store.dispatch('login', {username: data.values.username, id: data.values.id});
          this.$emit('showSnack', this.lang.loginOk, 'success');
          this.$router.push({name: 'Vote'});
        } else {
          this.$emit('showSnack', this.lang.userNotFound, 'error');
        }
      });

    },

  },

  mounted() {
    this.lang = this.$lang.it;
  },

  computed: {

    lang: {  

      get() {    
        switch(this.$store.getters.language) {
          case 'it':
            return this.$lang.it;
          case 'en':
            return this.$lang.en;
          case 'jp':
            return this.$lang.jp;
          case 'es':
            return this.$lang.es;
          case 'pt':
            return this.$lang.pt;
          case 'fr':
            return this.$lang.fr;
          default:
            return this.$lang.en;
        }
      },

      set(newLang) {
        return newLang
      }      

    }

  },

}

</script>

<style scoped>

  .home {
    text-align: center;
  }

</style>